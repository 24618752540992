body {
  margin: 0;
}

/* @import 'bootstrap/dist/css/bootstrap.min.css'; */

.sp-label-s1 {
  font: normal normal normal 13px/20px Nunito Sans;
  color: var(--shadow-57);
}

.p-error {
  font: normal normal normal 11px/17px Nunito Sans;
  letter-spacing: 0px;
  color: var(--required);
  margin: 3px;
}

.sp-w100p {
  width: 100% !important;
}

.sp-required {
  color: red;
}
