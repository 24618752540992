.signature-w {

    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 4px 10px;
    border-radius: 10px;
}

.campagin-details-w {

    margin-top: 30px;
    border-radius: 10px;
    padding: 10px 20px;


    h1,
    h2,
    h3,
    h4 {
        font-family: Nunito Sans;
    }

    .recharts-wrapper {

        margin-top: -90px !important;
        margin-left: -300px !important;
    }






}


.campagin-details-answered {

    margin-top: 30px;
    border-radius: 10px;
    padding: 10px 20px;


    h1,
    h2,
    h3,
    h4 {
        font-family: Nunito Sans;
    }

    .recharts-wrapper {

        margin-top: -90px !important;
        margin-left: -300px !important;
    }

    .sp-label-s1 {
        margin-left: 10px;
    }



}

.campagin-dtmf-report {

    margin-top: 30px;
    border-radius: 10px;
    padding: 10px 20px;

    .dtmf-report-table {

        margin-top: 120px;
    }

    h1,
    h2,
    h3,
    h4 {
        font-family: Nunito Sans;
    }




    .sp-label-s1 {
        margin-left: 10px;
    }


}



.c1-winner {
    color: green;
    font-size: 22px;
    font-weight: bold;
}

.c2-winner {
    color: #e6981c;
    font-size: 18px;
    font-weight: bold;
}

.c3-winner {
    color: #0088FE;
    font-size: 14px;
    font-weight: bold;
}

.c4-winner {
    color: #5c23cf;
    font-weight: bold;
}

.c5-winner {
    color: #e01272;
    font-weight: bold;
}

.c6-winner {
    color: #92d624;
    font-weight: bold;
}

.c7-winner {
    color: #28edaf;
    font-weight: bold;
}

.c8-winner {
    color: #0088FE;
    font-weight: bold;
}


@media print {

    .report-wrapper {

        padding: 20px;

    }

    .printable {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        display: block !important;
    }
}


.printable {
    display: none;
}


.no-campaign {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}