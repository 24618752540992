.signature-w {

    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 4px 10px;
    border-radius: 10px;
}


.select-all-chkbox {
    margin-top: 14px;
    margin-left: 10px;
}


.select-all-chklbl {
    margin-top: 16px;
    margin-left: 4px;
}


.mail-template {

    // font-family: Poppins;
    // font-size: 14px;
}

.create-org-title {
    font: normal normal normal 23px/34px Nunito Sans;
    letter-spacing: 0.14px;
    color: #171725;
    opacity: 0.8;
    font-weight: 600;
    margin-top: 0px;
}



.craete-org-subtitle {
    font: normal normal normal 16px/25px Nunito Sans;
    letter-spacing: 0.1px;
    color: var(--primary-color);
    opacity: 0.8;
    font-weight: 600;
    margin-bottom: 5px;

}




.cc .p-inputtext {
    width: 100%;

}


.email-box {


    height: 300px;
}



.sms-template-txt {
    padding: 10px;
    font: normal normal normal 13px/20px Nunito Sans;
    color: var(--shadow-10);
    margin: 2px;
    border-radius: 5px;
    line-height: 50px;
    box-shadow: 0px 3px 6px var(--shadow-85);
    border: 0.5px solid var(--theme-85);
    min-height: 100px;
}