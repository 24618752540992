.pe-config {

    min-height: calc(100vh - 200px);

    .no-config-txt {

        font: normal normal normal 13px/20px Nunito Sans;


    }

    .config-txt {
        font: normal normal normal 15px/20px Nunito Sans;
    }

}