.upload {
    background-color: #f5f5f5;
    padding: 10px 0px 0px 0px;
    padding: 0px;
    margin: 1%;
    height: 34px;

    .filename {
        font-weight: 600;

        font-size: 13px;
        margin: 10px 6px;
        width: 95%;
        white-space: nowrap;
        /* width: 50px; */
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        font-family: Nunito Sans;
    }

    .valid-file {
        color: var(--primary-color)
    }

    .invalid-file {
        color: red
    }

    .sp-progress-bar {
        padding-top: 10px;
    }

    .btnremovefile {
        cursor: pointer;
        margin-right: 5px;
        font-size: 20px;
    }

    // .btnremovefile:hover {
    //   background-color: #f1f1f1;
    // }
}

.btn-remove-file {
    margin: 0px;
    font-size: 30px;
    cursor: pointer;
    //border: 1px solid rgba(0,0,0,0.1);
    line-height: 1rem;
    padding: 4px 6px 9px 6px;
    margin-top: 10px;
    top: 6px;
    position: relative;
    color: rgba(0, 0, 0, 0.5);

}