.sp-menubar-w {
    // margin: 10px 20px;
    width: 100%;
    border-bottom: 1px solid rgba(32, 48, 83, 1);
    margin: auto;

    // margin-top: 10px;

    .login-txt {
        color: white;
        cursor: pointer;
        margin-right: 10px;
        //font-size: 14px;
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .app-title {
        color: white;
        display: block;
        // margin: 12px 0px 0px 10px;
        padding: 1rem;
        font-size: 24px;
        font-weight: 100;


        span {
            top: -19px;
            position: relative
        }
    }

    .sp-logo {
        width: 100%;
        height: 40px;
        padding: 3px 14px;
    }


    .sp-menubar {
        background: var(--theme-45) 0% 0% no-repeat padding-box;
        //   border-radius: 8px;
        font: normal normal bold 12px/16px Nunito Sans;
        // height: 54px;
        padding: 10px;

        img {
            cursor: pointer;
        }

        .sp-profile {
            font: normal normal 600 13px/16px Nunito Sans;
            letter-spacing: 0px;
            color: var(--theme-100);
            opacity: 0.8;
            margin-top: 28px;


            i {
                top: 3px;
                position: relative;
                cursor: pointer;
            }
        }


        .login-name {
            background-color: red;
        }

        .p-menubar-root-list {


            .m-menu-bar {}

            //   .p-menubar {

            .p-menuitem {
                font-size: 16px;
                color: #fff;
            }

            // .p-menuitem-text {
            //     font-size: 14px;
            //     color: #fff;
            //     opacity: 0.5;
            // }

            // .p-submenu-icon {
            //     color: #fff;
            // }

            // .p-menuitem-icon {
            //     color: #fff;
            // }

            .p-submenu-list {


                .p-menuitem {
                    .p-menuitem-text:hover {

                        color: var(--theme-45);
                    }
                }
            }
        }
    }

    .p-breadcrumb ul li:last-child .p-menuitem-text {
        color: var(--theme-45);
    }
}


// .sp-menubar-w {
//     // margin: 10px 20px;
//     width: 97%;

//     margin: auto;
//     margin-top: 10px;

//     .sp-logo {
//         width: 100%;
//         height: 40px;
//         padding: 3px 14px;
//     }


//     .sp-menubar {
//         background: var(--theme-45) 0% 0% no-repeat padding-box;
//         border-radius: 8px;
//         font: normal normal bold 12px/16px Nunito Sans;


//         img {
//             cursor: pointer;
//         }

//         .sp-profile {
//             font: normal normal 600 12px/16px Nunito Sans;
//             letter-spacing: 0px;
//             color: var(--theme-100);
//             opacity: 0.8;



//             i {
//                 top: 3px;
//                 position: relative;
//                 cursor: pointer;
//             }
//         }


//         .login-name {
//             background-color: red;
//         }

//         .p-menubar-root-list {


//             .m-menu-bar {}

//             //   .p-menubar {

//             .p-menuitem {
//                 font-size: 14px;
//                 color: #fff;
//             }

//             // .p-menuitem-text {
//             //     font-size: 14px;
//             //     color: #fff;
//             //     opacity: 0.5;
//             // }

//             // .p-submenu-icon {
//             //     color: #fff;
//             // }

//             // .p-menuitem-icon {
//             //     color: #fff;
//             // }

//             .p-submenu-list {


//                 .p-menuitem {
//                     .p-menuitem-text:hover {

//                         color: var(--theme-45);
//                     }
//                 }
//             }
//         }
//     }

//     .p-breadcrumb ul li:last-child .p-menuitem-text {
//         color: var(--theme-45);
//     }
// }


.sp-menu-active {
    // border-bottom: white 2px solid;
    border-bottom-width: 80%;

    .p-menuitem-text {
        opacity: 1.0 !important;

    }

}

.sp-sub-menu-active {
    .p-menuitem-text {
        color: var(--theme-45) !important;
    }
}


.log-out-menu {
    width: "200px";
    border-radius: 10px;
}


.log-out {


    ul {

        li {
            padding: 10px;
            font: normal normal bold 12px/16px Nunito Sans;
            font-size: 14px;
            align-items: center;
            text-align: center;

            span {
                margin-left: 10px;
                position: relative;
                top: -2px;
                color: rgba($color: #000000, $alpha: 0.7);
            }
        }
    }
}


.change-role {

    height: 60px;
    width: 260px;
    padding: 20px;
}


.footer {
    // background-color: var(--body-background);
    //   height: 50px;
    padding: 20px;
    text-align: center;

    .footer-p {
        font: normal normal normal 18px/24px Nunito Sans;
    }


    .all-rights {
        font: normal normal normal 14px/20px Nunito Sans;
    }
}


.body-container {
    min-height: calc(100vh - 188px);
}