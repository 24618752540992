.api-config {

    padding: 20px;
    //   min-height: calc(100vh - 200px);


    .config-txt {
        font: normal normal normal 15px/20px Nunito Sans;



        .api-key {


            margin-left: 20px;

        }


        .copy-txt {

            cursor: pointer;
            border: 1px solid rgba(0, 0, 0, 0.2);
            padding: 3px 6px;
            color: var(--shadow-30);
            border-radius: 5px;
            margin-left: 10px;
        }

        .copy-txt:hover {

            background-color: whitesmoke;
        }


    }


}



.api-details {

    //   min-height: calc(100vh - 200px);


    .config-txt {
        font: normal normal normal 15px/20px Nunito Sans;

        .api-key {
            margin-left: 20px;
        }

        .copy-txt {
            cursor: pointer;
            border: 1px solid rgba(0, 0, 0, 0.2);
            padding: 3px 6px;
            color: var(--shadow-30);
            border-radius: 5px;
            margin-left: 10px;
        }

        .copy-txt:hover {
            background-color: whitesmoke;
        }
    }
}



.copy-txt {

    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 3px 6px;
    color: var(--shadow-30);
    border-radius: 5px;
    margin-left: 10px;
}

.copy-txt:hover {

    background-color: whitesmoke;
}



.config-url span {
    color: blue;
}